import { NavLink } from 'react-router-dom';
import Juliet4 from '../../images/Juliet4.webp';
import styles from './ClassesPreview.module.css';

const ClassesPreview = () => {
    const handleNavLinkClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <div className={styles.classesContainer}>
            <div className={styles.imageContainer}>
                <img src={Juliet4} alt="Classes" className={styles.classesImage} />
            </div>
            <div className={styles.textContainer}>
                <NavLink to="/classes" className={styles.classesButton} onClick={handleNavLinkClick}>
                    LEARN ABOUT OUR CLASSES
                </NavLink>
            </div>
        </div>
    );
};

export default ClassesPreview;
