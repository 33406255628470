import { NavLink } from 'react-router-dom';
import Ballet4 from "../../images/Ballet4.webp";
import styles from './Announcement.module.css';

const announcementData = {
    main: "ENROLL NOW!",
    sub: "FALL CLASSES START AUGUST 19TH, 2024."
};

const Announcement = () => {
    const handleNavLinkClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <div className={styles.announcementContainer}>
            <img src={Ballet4} alt="Ballet Class" className={styles.announcementImage} />
            <NavLink to="/enroll" className={styles.announcementButton} onClick={handleNavLinkClick}>
                <span className={styles.mainText}>{announcementData.main}</span>
                <span className={styles.subText}>{announcementData.sub}</span>
            </NavLink>
        </div>
    );
};

export default Announcement;
