import Contemporary1 from '../../images/Contemporary1.webp';
import styles from './AboutUs.module.css';

const aboutData = [
  {
    title: "ABOUT US",
    description: [
      "Founded in 1945 by Dr. and Mrs. Fishback, Fishback Studio is regarded as one of the finest dance schools in the country. Our former students have gone on to dance in professional companies, on stage and screen, pre-professional programs and top level university dance programs. Our dancers are regularly awarded scholarships to intensives and universities. Well over 30,000 students have trained at Fishback Studio since its opening. Fishback Studio is and has been family owned and operated for 80 years this coming season.",
      "From 1980 to 2022, Kathie Fishback Anthony, and her husband, Michael Anthony, became the owner/operators. Kathie trained from the age of 3 at Fishback Studio and enjoyed 12 years of professional dancing in Hollywood, where she was seen dancing on the Carol Burnett Show, with Dean Martin and Shirley MacLaine, NBC Follies, Emmy and Academy Award Shows, among many others. Michael Anthony is a world-renowned professional guitarist.",
      "In 2022, Kathie’s daughter Krista King-Doherty, and her husband, Mark Doherty, took over as Director/business owners. Krista is an internationally recognized master dance instructor/choreographer, and Mark is an award-winning designer/builder/consultant.",
      "Classes begin in August each year. We are proud to offer high quality dance training in a supportive and encouraging environment. Classes are offered in all ages, genres and levels. We offer competition teams for the more serious dancers. Class sizes are limited and do fill, enroll now to ensure placement."
    ]
  }
];

const AboutUs = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.aboutUsImageContainer}>
        <img src={Contemporary1} alt="Ballet" className={styles.aboutUsImage} />
      </div>
      <div className={styles.aboutUsTextContainer}>
        {aboutData.map((item, index) => (
          <div key={index}>
            <h2 className={styles.aboutUsTitle}>{item.title}</h2>
            {item.description.map((desc, idx) => (
              <p key={idx} className={styles.aboutUsDescription}>{desc}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
