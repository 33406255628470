import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styles from './Header.module.css';

const Header = () => {
  const scrollToFooter = () => {
    document.getElementById('footer').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <header className={styles.headerContainer}>
        <div className={styles.leftSection}>
          <div className={styles.logo}>
            FISHBACK STUDIO
          </div>
        </div>
        <div className={styles.rightSection}>
          <a href="https://www.facebook.com/profile.php?id=100063570911443" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className={styles.icon} />
          </a>
          <a href="https://www.instagram.com/fishbackstudioofficial/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
          </a>
          <div className={styles.navLinks}>
            <NavLink to="/about" className={styles.navLink}>
              ABOUT
            </NavLink>
            <NavLink to="/contact" className={styles.navLink}>
              CONTACT
            </NavLink>
          </div>
          <div className={styles.mobileMenuIcon} onClick={scrollToFooter}>
            MENU
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
