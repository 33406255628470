import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import Enroll from "./pages/Enroll/Enroll";
import About from "./pages/About/About";
import Schedule from "./pages/Schedule/Schedule";
import DressCode from "./pages/DressCode/DressCode";
import ImportantDates from "./pages/ImportantDates/ImportantDates";
import Competition from "./pages/Competition/Competition";
import ClassDetail from "./pages/ClassDetail/ClassDetail";
import Footer from "./components/Footer/Footer";
import useTitle from "./hooks/useTitle";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import 'normalize.css';
import './App.css';

function App() {
  useTitle();
  
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/enroll" element={<Enroll />} />
        <Route path="/about" element={<About />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/dress-code" element={<DressCode />} />
        <Route path="/important-dates" element={<ImportantDates />} />
        <Route path="/competition" element={<Competition />} />
        <Route path="/classes" element={<ClassDetail />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
