import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import White1 from '../../images/White1.webp';
import styles from './Competition.module.css';

const competitionData = [
  {
    title: "COMPETITION DANCE",
    description: [
      "Fishback Studio is proud to offer competition dance for the more serious dancers aged 8 and up. Fishback Dance Company (FDC) dancers compete regionally and nationally each season in all genres of dance. Auditions are held each August and September.",
      "Fishback Studio is renowned for our high level of technique and professional presentation. Our dancers are regularly awarded top honors and scholarships.",
    ],
  },
  {
    title: "Awards and Achievements of the Fishback Studio Competition Dancers include:",
    description: [
      "Overall High Score Awards, National Title Winners, Best Choreography, Best Technical Execution, Teacher’s Choice Awards, Best Emotional Execution, Best Precision, Dancer of the Year contestants, National Center Stage Awards, Entertainment Challenge Winners."
    ],
  },
  {
    title: "Scholarships Awarded include:",
    description: [
      "NUVO Breakout Artist finalists and winners, NYCDA Outstanding Dancer finalists and winners, Broadway Dance Center (NYC), STEPS on Broadway (NYC), American Ballet Theater, The Ailey School (Alvin Ailey Dance Theater, NYC), San Francisco Ballet School, The Rock School for Dance Education, Orlando Ballet School, Princess Grace Academy, Australian Ballet School, John Cranko School, Walnut Hill School, University of Utah, University of Arizona, University of New Mexico, Chapman University, Point Park University."
    ],
  },
  {
    title: "YAGP (Youth America Grand Prix):",
    description: [
      "NYC Finalists 2005-2023, Junior and Senior Women’s Gold Medalist, NYC Finals (Juliet Doherty), Youth Grand Prix Awards, Hope Award, Overall Winners and placement in Contemporary and Classical Categories at Semi-Finals, Winners in Ensemble Category, Top Twelve Finalists."
    ],
  }
];

const Competition = () => {
  const competitionRef = useRef(null);
  const competitionInView = useInView(competitionRef, { triggerOnce: true });

  return (
    <div className={styles.competitionContainer}>
      <button className={styles.backButton} onClick={() => window.history.back()}>BACK</button>
      <motion.div
        ref={competitionRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: competitionInView ? 1 : 0 }}
        transition={{ duration: 0.8 }}
        className={styles.competitionContent}
      >
        <div className={styles.textContainer}>
          {competitionData.map((item, index) => (
            <div key={index} className={styles.competitionSection}>
              <h2 className={styles.competitionTitle}>{item.title}</h2>
              {item.description.map((desc, idx) => (
                <p key={idx} className={styles.competitionDescription}>{desc}</p>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.imageContainer}>
          <img src={White1} alt="Competition Dance" className={styles.competitionImage} />
        </div>
      </motion.div>
    </div>
  );
};

export default Competition;
