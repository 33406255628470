import { useRef } from "react";
import { motion, useScroll } from "framer-motion";
import styles from "./ClassDetail.module.css";
import classDetailData from "./ClassDetailData";

const ClassDetail = () => {
  const ref = useRef(null);
  const { scrollXProgress } = useScroll({
    container: ref,
    layoutEffect: false,
  });

  return (
    <div className={styles.detailContainer}>
      <button onClick={() => window.history.back()} className={styles.backButton}>BACK</button>
      <h2 className={styles.detailTitle}>CLASSES</h2>
      <p className={styles.introText}>
        Whether you are 3 or 83, if your goal is to become a professional dancer or to dance for enjoyment and exercise: FISHBACK STUDIO provides a patient, encouraging environment with a primary focus on proper dance training for the students.
      </p>
      <div className={styles.scrollListContainer}>
        <ul ref={ref} className={styles.scrollList}>
          {classDetailData.map((classItem, index) => (
            <div key={index} className={styles.classGroup}>
              <li className={styles.classItem}>
                <img src={classItem.image} alt={classItem.title} className={styles.classImage} />
              </li>
              <li className={styles.classItem}>
                <h3 className={styles.classTitle}>{classItem.title}</h3>
                <p className={styles.classAge}>{classItem.age}</p>
                <p className={styles.classDescription}>{classItem.description}</p>
              </li>
            </div>
          ))}
        </ul>
        <svg className={styles.progress} width="100" height="100" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="30" pathLength="1" className={styles.bg} />
          <motion.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className={styles.indicator}
            style={{ pathLength: scrollXProgress }}
          />
        </svg>
      </div>
      <p className={styles.outroText}>
        Contact us for more information on our wide variety of classes available for all ages and levels.
      </p>
    </div>
  );
};

export default ClassDetail;
