import headshot from '../../images/headshot.webp';
import styles from './Director.module.css';

const directorData = {
  title: "MEET OUR DIRECTOR",
  description: [
    "Krista King-Doherty is an internationally recognized and award-winning teacher and choreographer. Krista comes from the Fishback Family lineage of four generations of professional dancers. Prior to returning to her dance roots, Krista enjoyed a successful career as a fashion model in Europe, USA, Asia and Australia. She worked for top designers and was featured in print campaigns, magazines, on television and runways for a decade.",
    "Since 2005, Krista's students have repeatedly won the Youth Grand Prix Award, Grand Prix Award, and the Hope Award, in addition to overall awards in all divisions at Youth America Grand Prix. Krista’s daughter and lifelong student, Juliet Doherty, won the Gold Medal in both Junior Women’s and Senior Women’s Division at the YAGP Finals 2012/2014.",
    "Krista’s work is frequently featured in galas and has been recognized in the “Stars of Tomorrow” at Lincoln Center, World Ballet Competition, ADC/IBC, Vail International Dance Festival, the Beijing International Ballet and Choreography Competition, Varna International Ballet Competition, USA/IBC, Australian Dance Festival, YGP Mexico, “Gala de Danza” in Cabo San Lucas, Nervi Festival, and Cancun International Ballet Festival.",
    "Krista is honored to have received the “Outstanding Choreographer” award from YAGP 4-times, most recently in 2024 at the YAGP Finals in New York City, and the “Avant Garde” award for choreography from ADC/IBC. Krista’s choreography was featured on NBC’s World of Dance Season 4. She teaches and adjudicates for national conventions and competitions. Krista is a sought-after choreographer and guest master teacher at conservatories and intensives in the USA and internationally.",
    "Recent student scholarships include San Francisco Ballet School, Houston Ballet Academy, Royal Ballet School, The Ailey School, Alonzo King’s Lines Ballet, Princess Grace Academy, the School of American Ballet, American Ballet Theatre and numerous top university programs. Student engagements include feature films, Broadway Shows, the Radio City Rockettes, Little Dancer at The Kennedy Center, and with professional dance companies in the USA and abroad."
  ]
};

const Director = () => {
  return (
    <div className={styles.directorContainer}>
      <div className={styles.directorTextContainer}>
        <h2 className={styles.directorTitle}>{directorData.title}</h2>
        {directorData.description.map((desc, idx) => (
          <p key={idx} className={styles.directorDescription}>{desc}</p>
        ))}
      </div>
      <div className={styles.directorImageContainer}>
        <img src={headshot} alt="Director" className={styles.directorImage} />
      </div>
    </div>
  );
};

export default Director;
