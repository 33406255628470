const classScheduleData = {
    MONDAY: [
      { time: "4:30 PM", room: "RM 1", class: "Pre Pointe/Pointe" },
      { time: "4:30 PM", room: "RM 2", class: "Hip Hop I-II Youth" },
      { time: "4:30 PM", room: "RM 3", class: "Pre Kinder Dance 4.5-5.5" },
      { time: "4:30 PM", room: "RM 4", class: "Tap Int Youth" },
      { time: "5:30 PM", room: "RM 1", class: "Ballet IV-V Youth" },
      { time: "5:30 PM", room: "RM 2", class: "Hip Hop Int/Adv" },
      { time: "5:30 PM", room: "RM 3", class: "Modern Youth" },
      { time: "5:30 PM", room: "RM 4", class: "Tap Int Older" },
      { time: "6:30 PM", room: "RM 1", class: "Adult Ballet Int/Adv" },
      { time: "6:30 PM", room: "RM 2", class: "Lyrical/Contemp 13+" },
      { time: "6:30 PM", room: "RM 3", class: "Adult Hip Hop Fusion" },
      { time: "7:30 PM", room: "RM 1", class: "Adult Modern" },
      { time: "7:30 PM", room: "RM 2", class: "Hip Hop Adv" },
    ],
    TUESDAY: [
      { time: "10:30 AM", room: "RM 1", class: "Adult Ballet" },
      { time: "1:00 PM", room: "RM 1", class: "Flamenco" },
      { time: "4:30 PM", room: "RM 1", class: "Jazz Basic Youth" },
      { time: "4:30 PM", room: "RM 2", class: "Ballet/Pointe II-III" },
      { time: "4:30 PM", room: "RM 3", class: "Tap Basic Youth" },
      { time: "4:30 PM", room: "RM 4", class: "Pre Tap 4.5-6.5" },
      { time: "5:30 PM", room: "RM 1", class: "Ballet III-IV Youth" },
      { time: "5:30 PM", room: "RM 2", class: "Ballet/Pointe II-III" },
      { time: "5:30 PM", room: "RM 3", class: "Pre Hip Hop 4.5-6.5" },
      { time: "5:30 PM", room: "RM 4", class: "Tap Int Youth" },
      { time: "6:30 PM", room: "RM 1", class: "Jazz/Contemp Int" },
      { time: "6:30 PM", room: "RM 2", class: "FDC Skills" },
      { time: "6:30 PM", room: "RM 3", class: "Adult Tap Int/Adv" },
      { time: "6:30 PM", room: "RM 4", class: "Tap Adv Students" },
      { time: "7:30 PM", room: "RM 1", class: "Jazz/Contemp Int/Adv" },
      { time: "7:30 PM", room: "RM 2", class: "Jazz/Contemp Adv" },
      { time: "7:30 PM", room: "RM 3", class: "Adult Tap Basic/Int" },
      { time: "7:30 PM", room: "RM 4", class: "Scottish/Highland" },
    ],
    WEDNESDAY: [
      { time: "3:30 PM", room: "RM 1", class: "Ballet II-III A" },
      { time: "3:30 PM", room: "RM 3", class: "Pre Kinder Dance 4.5-5.5" },
      { time: "4:30 PM", room: "RM 1", class: "Jazz II-III Youth" },
      { time: "4:30 PM", room: "RM 2", class: "Ballet/Pointe Adv" },
      { time: "4:30 PM", room: "RM 3", class: "Hip Hop Basic/Int Youth" },
      { time: "4:30 PM", room: "RM 4", class: "Ballet II-III A" },
      { time: "5:30 PM", room: "RM 1", class: "Jazz/Contemp Yng Int/Adv" },
      { time: "5:30 PM", room: "RM 2", class: "Ballet/Pointe Adv" },
      { time: "5:30 PM", room: "RM 3", class: "Hip Hop Int Teen" },
      { time: "6:30 PM", room: "RM 1", class: "Acrobatics Int/Adv" },
      { time: "6:30 PM", room: "RM 2", class: "Latin Salsa" },
      { time: "6:30 PM", room: "RM 3", class: "Teacher's Tap" },
      { time: "7:30 PM", room: "RM 1", class: "Acrobatics Adv" },
      { time: "7:30 PM", room: "RM 2", class: "Teacher's Jazz/Contemp" },
      { time: "7:30 PM", room: "RM 3", class: "Men's Tap" },
    ],
    THURSDAY: [
      { time: "10:30 AM", room: "RM 1", class: "Adult Ballet Int" },
      { time: "4:30 PM", room: "RM 1", class: "Hip Hop II-III Youth" },
      { time: "4:30 PM", room: "RM 2", class: "Ballet/Pointe III-IV" },
      { time: "4:30 PM", room: "RM 3", class: "Pre Kinder Dance 3-4's" },
      { time: "5:30 PM", room: "RM 1", class: "Hip Hop Int Younger" },
      { time: "5:30 PM", room: "RM 2", class: "Ballet/Pointe III-IV" },
      { time: "5:30 PM", room: "RM 3", class: "Adult Tap Int" },
      { time: "6:30 PM", room: "RM 1", class: "Ballet Teen Basic/Int" },
      { time: "6:30 PM", room: "RM 2", class: "FDC Workshop" },
      { time: "6:30 PM", room: "RM 3", class: "Adult Hip Hop Fusion" },
      { time: "7:30 PM", room: "RM 1", class: "Latin Salsa" },
      { time: "7:30 PM", room: "RM 2", class: "FDC Skills" },
      { time: "7:30 PM", room: "RM 4", class: "Adult Ballet Basic/Int" },
    ],
    FRIDAY: [
      { time: "4:30 PM", room: "RM 1", class: "Acrobatics Primary" },
      { time: "4:30 PM", room: "RM 2", class: "FDC Superstars" },
      { time: "4:30 PM", room: "RM 3", class: "Jazz Teen I-II" },
      { time: "5:30 PM", room: "RM 1", class: "Acrobatics Int Youth" },
      { time: "5:30 PM", room: "RM 2", class: "FDC Novas" },
      { time: "6:30 PM", room: "RM 1", class: "Acrobatics Int Older" },
    ],
    SATURDAY: [
      { time: "9:00 AM", room: "RM 1", class: "Jazz I Youth" },
      { time: "9:00 AM", room: "RM 2", class: "Pre Kinder Dance 4.5-5.5" },
      { time: "9:00 AM", room: "RM 3", class: "Pre Kinder Dance 3-4's" },
      { time: "9:00 AM", room: "RM 4", class: "Pre Tap 4.5-6.5" },
      { time: "10:00 AM", room: "RM 1", class: "Ballet I-II Youth" },
      { time: "10:00 AM", room: "RM 2", class: "Ballet II-III A" },
      { time: "10:00 AM", room: "RM 3", class: "Pre Kinder Dance 3-4's" },
      { time: "10:00 AM", room: "RM 4", class: "Pre Hip Hop 4.5-6.5" },
      { time: "11:00 AM", room: "RM 1", class: "Modern Int/Adv" },
      { time: "11:00 AM", room: "RM 2", class: "Jazz Int Youth" },
      { time: "11:00 AM", room: "RM 3", class: "Ballet II-III A" },
      { time: "12:00 PM", room: "RM 1", class: "Acrobatics Youth" },
      { time: "12:00 PM", room: "RM 2", class: "Ballet/Pointe IV" },
      { time: "12:00 PM", room: "RM 3", class: "Ballet Int Youth" },
      { time: "1:00 PM", room: "RM 2", class: "Ballet/Pointe IV" },
      { time: "2:00 PM", room: "RM 1", class: "Jazz/Contemp Adv" },
    ],
  };

export default classScheduleData;