import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ClassSchedule from '../../components/ClassSchedule/ClassSchedule';
import styles from './Schedule.module.css';

const Schedule = () => {
    const navigate = useNavigate(); 
    const scheduleRef = useRef(null);

    const scheduleInView = useInView(scheduleRef, { triggerOnce: true });

    const handleBackClick = () => {
        navigate('/'); 
    };

    return (
        <div className={styles.scheduleContainer}>
            <button onClick={handleBackClick} className={styles.backButton}>BACK</button>
            <motion.div
                ref={scheduleRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: scheduleInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <ClassSchedule />
            </motion.div>
        </div>
    );
};

export default Schedule;