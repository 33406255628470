import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Blue1 from '../../images/Blue1.webp';
import styles from './DressCode.module.css';

const dressCodeData = [
  {
    title: "DRESS CODE",
    description: [
      "Proper dance attire and shoes are required for all classes. Please check with the studio for your specific class if you have further questions."
    ]
  },
  {
    classTitle: "PRE-KINDER DANCE",
    girlsDescription: "(GIRLS) Royal blue leotard with initials, pink tights and pink ballet slippers. Small skirts are allowed. Children’s ballet outfits are available for sale at the studio, please bring your child to fit when purchasing an outfit for proper fit.",
    boysDescription: "(BOYS) Fishback Studio blue t-shirt, black shorts, jazz pants, or sweatpants, black ballet shoes. Shirts are available for sale at the studio."
  },
  {
    classTitle: "BALLET",
    girlsDescription: "(GIRLS) Royal blue leotard with pink tights and pink ballet slippers. Small skirts or tight fitting shorts are allowed. Children’s ballet outfits are available for sale at the studio, please bring your child to fit when purchasing an outfit for proper fit.",
    boysDescription: "(BOYS) T-shirt with any type of shorts, jazz pants, or sweatpants and black ballet shoes."
  },
  {
    classTitle: "POINTE",
    description: "Black leotard with pink tights, pointe and ballet shoes. Small skirts or tight fitting shorts allowed. Advanced students and adults may wear black tights."
  },
  {
    classTitle: "HIP HOP, LYRICAL/CONTEMPORARY, PRE-HIP HOP, PRE-TAP, SALSA HEELS, JAZZ, TAP, MODERN, ACROBATICS and HIGHLAND",
    description: "Any color bike shorts, jazz pants, leggings, tights, or sweatpants with leotard, sports bra or t-shirt and appropriate dance shoes."
  }
];

const DressCode = () => {
    const navigate = useNavigate(); 
    const dressCodeRef = useRef(null);
    const dressCodeInView = useInView(dressCodeRef, { triggerOnce: true });

    const handleBackClick = () => {
        navigate('/'); 
    };

    return (
        <div className={styles.dressCodeContainer}>
            <button onClick={handleBackClick} className={styles.backButton}>BACK</button>
            <motion.div
                ref={dressCodeRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: dressCodeInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <div className={styles.textContainer}>
                    {dressCodeData.map((item, index) => (
                        <div key={index}>
                            {item.title && <h2 className={styles.dressCodeTitle}>{item.title}</h2>}
                            {item.classTitle && <h3 className={styles.classTitle}>{item.classTitle}</h3>}
                            {item.description && <p className={styles.dressCodeDescription}>{item.description}</p>}
                            {item.girlsDescription && <p className={styles.dressCodeDescription}>{item.girlsDescription}</p>}
                            {item.boysDescription && <p className={styles.dressCodeDescription}>{item.boysDescription}</p>}
                        </div>
                    ))}
                </div>
                <div className={styles.imageContainer}>
                    <img src={Blue1} alt="Dress Code" className={styles.dressCodeImage} />
                </div>
            </motion.div>
        </div>
    );
};

export default DressCode;
