import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Mission from '../../components/Mission/Mission';
import AboutUs from '../../components/AboutUs/AboutUs';
import Director from '../../components/Director/Director';
import styles from './About.module.css';

const About = () => {
    const navigate = useNavigate();
    const missionRef = useRef(null);
    const aboutUsRef = useRef(null);
    const directorRef = useRef(null);

    const missionInView = useInView(missionRef, { triggerOnce: true });
    const aboutUsInView = useInView(aboutUsRef, { triggerOnce: true });
    const directorInView = useInView(directorRef, { triggerOnce: true });

    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className={styles.aboutContainer}>
            <button onClick={handleBackClick} className={styles.backButton}>BACK</button>
            <motion.div
                ref={missionRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: missionInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <Mission />
            </motion.div>
            <motion.div
                ref={aboutUsRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: aboutUsInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <AboutUs />
            </motion.div>
            <motion.div
                ref={directorRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: directorInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <Director />
            </motion.div>
        </div>
    );
};

export default About;