import Ballet1 from '../../images/Ballet1.webp';
import styles from './Mission.module.css';

const missionData = [
    {
        title: "A SCHOOL OF DIGNITY AND DISTINCTION DEVOTED TO THE ART OF DANCE.",
        description1: "We provide the finest dance education to students of all ages and levels in a caring, nurturing environment. We strive to inspire a lasting love and appreciation for dance and the arts. Our commitment is that each student grows and develops as dancers and in character.",
        description2: "Fishback Studio of the Dance, provides students with a wide variety of instruction, convenient class schedules, and excellent opportunities for recreation, competition and performance."
    }
];

const Mission = () => {
    return (
        <div className={styles.missionContainer}>
            <div className={styles.textContainer}>
                {missionData.map((item, index) => (
                    <div key={index} className={styles.missionContent}>
                        <h2 className={styles.missionTitle}>{item.title}</h2>
                        <p className={styles.missionDescription}>{item.description1}</p>
                        <p className={styles.missionDescription}>{item.description2}</p>
                    </div>
                ))}
            </div>
            <div className={styles.imageContainer}>
                <img src={Ballet1} alt="Ballet" className={styles.missionImage} />
            </div>
        </div>
    );
};

export default Mission;
