import { useState } from 'react';
import balletKids from "../../images/balletKids.jpg";
import styles from './GetInTouch.module.css';

const GetInTouch = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const links = [
    { href: "https://www.instagram.com/fishbackstudioofficial/", text: "INSTAGRAM" },
    { href: "https://www.facebook.com/profile.php?id=100063570911443", text: "FACEBOOK" }
  ];

  return (
    <div className={styles.contactContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.textContainer}>
          {links.map((link, index) => (
            <a
              key={link.text}
              href={link.href}
              target="_blank" 
              rel="noopener noreferrer"
              className={`${styles.link} ${hoveredIndex !== null && hoveredIndex !== index ? styles.otherLinks : ''}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {link.text}
            </a>
          ))}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src={balletKids} alt="Contact" className={styles.backgroundImage} />
      </div>
    </div>
  );
};

export default GetInTouch;
