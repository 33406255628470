import { useState } from 'react';
import styles from './Footer.module.css'; 
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [hoveredNavIndex, setHoveredNavIndex] = useState(null);
  const [hoveredSocialIndex, setHoveredSocialIndex] = useState(null);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleNavLinkClick = (path) => {
    navigate(path);
    scrollToTop();
  };

  const navLinks = [
    { name: 'HOME', path: '/' },
    { name: 'ABOUT', path: '/about' },
    { name: 'ENROLL', path: '/enroll' },
    { name: 'CONTACT', path: '/contact' },
    { name: 'CLASSES', path: '/classes' },
    { name: 'IMPORTANT DATES', path: '/important-dates' },
    { name: 'SCHEDULE', path: '/schedule' },
    { name: 'DRESS CODE', path: '/dress-code' },
    { name: 'COMPETITION', path: '/competition' },
  ];

  const socialLinks = [
    { name: 'INSTAGRAM', href: 'https://www.instagram.com/fishbackstudioofficial/' },
    { name: 'FACEBOOK', href: 'https://www.facebook.com/profile.php?id=100063570911443' },
    { name: 'fishbackstudio@gmail.com', href: 'mailto:fishbackstudio@gmail.com' },
    { name: '(505) 298-8828', href: 'tel:+15052988828' }
  ];

  const handleNavMouseEnter = (index) => {
    setHoveredNavIndex(index);
  };

  const handleNavMouseLeave = () => {
    setHoveredNavIndex(null);
  };

  const handleSocialMouseEnter = (index) => {
    setHoveredSocialIndex(index);
  };

  const handleSocialMouseLeave = () => {
    setHoveredSocialIndex(null);
  };

  return (
    <footer id='footer' className={styles.footerContainer}>
      <div className={styles.footerBorder}></div>
      <div className={styles.footerSection}>
        <h4 className={styles.footerTitle}>NAVIGATE</h4>
        <ul className={styles.footerList}>
          {navLinks.map((link, index) => (
            <li key={link.name} className={styles.footerListItem}>
              <button 
                className={`${styles.footerLink} ${hoveredNavIndex !== null && hoveredNavIndex !== index ? styles.otherLinks : ''}`}
                onMouseEnter={() => handleNavMouseEnter(index)}
                onMouseLeave={handleNavMouseLeave}
                onClick={() => handleNavLinkClick(link.path)}
              >
                {link.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.footerSection}>
        <h4 className={styles.footerTitle}>CONNECT</h4>
        <ul className={styles.footerList}>
          {socialLinks.map((link, index) => (
            <li key={link.name} className={styles.footerListItem}>
              <a 
                href={link.href} 
                target="_blank" 
                rel="noopener noreferrer" 
                className={`${styles.footerLink} ${hoveredSocialIndex !== null && hoveredSocialIndex !== index ? styles.otherLinks : ''}`}
                onMouseEnter={() => handleSocialMouseEnter(index)}
                onMouseLeave={handleSocialMouseLeave}
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.footerSection}>
        <h4 className={styles.footerTitle}>STUDIO</h4>
        <address className={styles.footerAddress}>
          <a href="https://maps.google.com/?q=4529+Eubank+NE,+Albuquerque,+NM,+87111" target="_blank" rel="noopener noreferrer" className={styles.footerLink}>
            4529 EUBANK NE<br />
            ALBUQUERQUE, NM, 87111
          </a>
        </address>
      </div>
      <div className={styles.footerBottom}>
        <p className={styles.footerText}>© {currentYear} FISHBACK STUDIO. ALL RIGHTS RESERVED.</p>
      </div>
      <div className={styles.backToTopContainer}>
        <button className={styles.backToTop} onClick={scrollToTop}>BACK TO TOP</button>
      </div>
    </footer>
  );
};

export default Footer;
