import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Tap1 from '../../images/Tap1.webp';
import styles from './ImportantDates.module.css';

const importantDates = [
    { date: "August 19, 2024", event: "First day of classes" },
    { date: "September 2, 2024", event: "Labor Day (No classes!)" },
    { date: "October 24-30, 2024", event: "Costume Week" },
    { date: "October 31, 2024", event: "Studio Closed! (No classes!)" },
    { date: "November 27-30, 2024", event: "Thanksgiving Holiday (No classes!)" },
    { date: "December 16-21, 2024", event: "Winter Dance Performances (In Studio)" },
    { date: "December 23 2024 – January 5, 2025", event: "Winter Holiday (No classes!)" },
    { date: "January 6, 2025", event: "Classes Resume" },
    { date: "May 24, 2025", event: "Last Day Classes" },
    { date: "June 2, 2025", event: "First day of summer classes" },
];

const ImportantDates = () => {
    const navigate = useNavigate();
    const datesRef = useRef(null);
    const datesInView = useInView(datesRef, { triggerOnce: true });

    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className={styles.datesContainer}>
            <button onClick={handleBackClick} className={styles.backButton}>BACK</button>
            <motion.div
                ref={datesRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: datesInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <div className={styles.textContainer}>
                    <h2 className={styles.datesTitle}>IMPORTANT DATES</h2>
                    <div className={styles.datesListContainer}>
                        <ul className={styles.datesList}>
                            {importantDates.map((item, index) => (
                                <li key={index} className={styles.dateItem}>
                                    <span className={styles.date}>{item.date}</span>
                                    <span className={styles.event}>{item.event}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.footerNote}>
                        <p>Calendar fall camps/drop-in events pop-ups/workshops (follow on social media)/summer session/camps</p>
                    </div>
                </div>
                <div className={styles.imageContainer}>
                    <img src={Tap1} alt="Ballet" className={styles.datesImage} />
                </div>
            </motion.div>
        </div>
    );
};

export default ImportantDates;
