import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; 
import ContactForm from '../../components/ContactForm/ContactForm';
import styles from './Enroll.module.css';

const Contact = () => {
    const navigate = useNavigate(); 
    const contactFormRef = useRef(null);

    const contactFormInView = useInView(contactFormRef, { triggerOnce: true });

    const handleBackClick = () => {
        navigate('/'); 
    };

    return (
        <div className={styles.enrollContainer}>
            <button onClick={handleBackClick} className={styles.backButton}>BACK</button>
            <motion.div
                ref={contactFormRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: contactFormInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
                className={styles.childContainer}
            >
                <ContactForm />
            </motion.div>
        </div>
    );
};

export default Contact;
