import preKinderImage from '../../images/preKinderImage.webp';
import preHipHopImage from '../../images/preHipHopImage.webp';
import preTapImage from '../../images/preTapImage.webp';
import pointeImage from '../../images/pointeImage.webp';
import hipHopImage from '../../images/hipHopImage.webp';
import jazzImage from '../../images/jazzImage.webp';
import lyricalContemporaryImage from '../../images/lyricalContemporaryImage.webp';
import tapImage from '../../images/tapImage.webp';
import acrobaticsImage from '../../images/acrobaticsImage.webp';
import modernImage from '../../images/modernImage.webp';
import highlandDanceImage from '../../images/highlandDanceImage.jpg';
import Contemporary1 from '../../images/Contemporary1.webp';
import salsaHeelsImage from '../../images/salsaHeelsImage.webp';
import adultProgramImage from '../../images/adultProgramImage.webp';
import juliet9 from '../../images/juliet9.webp';

const classDetailsData = [
  {
    image: preKinderImage,
    title: "Pre-Kinder Dance",
    age: "Ages 3 to 5 yrs",
    description: "Our highly successful program for ages 3-5 enhances creativity through dance and singing with a focus on large muscle coordination skills. Each week we use a different rhythmic instrument and learn basic gymnastic skills at the end of each class. Learn to dance while taking instruction from your teachers and cooperating with other students at your age and level. In addition to learning dances to perform, children learn to take turns, lead and follow, and work with fellow classmates. Class levels are divided by age: 3 & 4 yrs./ 4 & 5 yrs. Our wonderful faculty have extensive training in developing each child’s emotional and physical abilities. Live piano music is used."
  },
  {
    image: preHipHopImage,
    title: "Pre-Hip Hop",
    age: "Ages 4.5 to 6.5 yrs",
    description: "An introduction to the basics of hip hop dance, focusing on rhythm, coordination, and fun. This class is designed to build confidence and prepare young dancers for future hip hop classes."
  },
  {
    image: preTapImage,
    title: "Pre-Tap",
    age: "Ages 4.5 to 6.5 yrs",
    description: "This class introduces young dancers to the basics of tap dance. Emphasizing rhythm and coordination, students will learn fundamental tap steps and simple routines in a fun and engaging environment."
  },
  {
    image: juliet9,
    title: "Classical Ballet",
    age: "Ages 6 yrs.- adult",
    description: "Ballet is the foundation of all dance. French terminology, choreography, and repertoire. Beginning, intermediate, and advanced classes are offered for children, teens, and adults. Live piano music is used with the younger classes."
  },
  {
    image: pointeImage,
    title: "Pointe",
    age: "",
    description: "Pointe training is available as part of intermediate and advanced ballet classes. Proper preparatory ballet training is required. The student must be of suitable age and development."
  },
  {
    image: hipHopImage,
    title: "Hip Hop",
    age: "Ages 6 yrs.- adult",
    description: "A dance style for everyone! So much fun and energy in every class!"
  },
  {
    image: jazzImage,
    title: "Jazz",
    age: "Ages 6 yrs. - adult",
    description: "Learn the latest moves, leaps and turns. Technique, combinations, and choreography are presented and practiced in each class."
  },
  {
    image: lyricalContemporaryImage,
    title: "Lyrical & Contemporary",
    age: "Ages 6 yrs. - adult",
    description: "These expressive and current styles of dance are integral to offer students more versatility in their training."
  },
  {
    image: tapImage,
    title: "Tap",
    age: "Ages 6 yrs. - adult",
    description: "Kids and adults enjoy this rhythmic approach to dance. As you progress, feel the challenge rise! Tap is always popular in Broadway shows and so much fun to learn!"
  },
  {
    image: acrobaticsImage,
    title: "Acrobatics",
    age: "Ages 6 yrs. - Teen",
    description: "Learn flexibility and skills appropriate for your level from the basics to advanced gymnastic floor exercise. Acrobatic class is extremely popular and always fills quickly."
  },
  {
    image: modernImage,
    title: "Modern",
    age: "Ages 6 yrs. - adult",
    description: "Explore creative dance with influences of Horton and Graham techniques integrated with current contemporary styles. Move freely and express dance in a different way. Class may be taken in half-soles or bare feet."
  },
  {
    image: highlandDanceImage,
    title: "Highland Dance",
    age: "Ages 6 yrs. - adult",
    description: "A celebration of the Highland spirit, the dances are a spectacular combination of strength, agility, movement, music, and costume. Learn dances such as the Highland Fling and more!"
  },
  {
    image: Contemporary1,
    title: "Competition Teams",
    age: "",
    description: "Fishback Studio is proud to offer competition teams for the more serious dancer. Auditions are held annually each August and September. Dancers can audition for a spot on one of our two Dance Teams, our Tap team, as a soloist or dance in a group. Our students compete in all genres of dance at the regional and national level. Each season we attend workshops and competitions such as NUVO, NYCDA (New York City Dance Alliance), IDC (International Dance Challenge), Danceamerica, YAGP (Youth America Grand Prix), Cathy Roe’s Ultimate Dance, Starpower, Showstopper, VIP and others."
  },
  {
    image: salsaHeelsImage,
    title: "Salsa Heels",
    age: "Ages 12 +",
    description: "So much fun to learn the latest social dance skills!"
  },
  {
    image: adultProgramImage,
    title: "NEW MEXICO’S LARGEST ADULT PROGRAM",
    age: "",
    description: "Ballet, Tap, Hip Hop Fusion, Salsa Heels, Flamenco, Off Broadway Performance Company. Contact us for more information!"
  }
];

export default classDetailsData;