import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useTitle() {
  const location = useLocation();

  useEffect(() => {
    const baseTitle = "Fishback Studio";
    let title = baseTitle;

    switch (location.pathname) {
      case "/":
        title = "FISHBACKSTUDIO.COM";
        break;
      case "/about":
        title = "ABOUT | " + baseTitle;
        break;
      case "/enroll":
        title = "ENROLL | " + baseTitle;
        break;
      case "/contact":
        title = "CONTACT | " + baseTitle;
        break;
      case "/classes":
        title = "CLASSES | " + baseTitle;
        break;
      case "/important-dates":
        title = "IMPORTANT DATES | " + baseTitle;
        break;
      case "/schedule":
        title = "SCHEDULE | " + baseTitle;
        break;
      case "/dress-code":
        title = "DRESS CODE | " + baseTitle;
        break;
      case "/competition":
        title = "COMPETITION | " + baseTitle;
        break;
      default:
        title = baseTitle;
        break;
    }

    document.title = title;
  }, [location]);
}

export default useTitle;
