import { useRef } from "react";
import { motion, useScroll } from "framer-motion";
import styles from "./ClassSchedule.module.css";
import classScheduleData from "./ClassScheduleData";

const ClassSchedule = () => {
  const ref = useRef(null);
  const { scrollXProgress } = useScroll({
    container: ref,
    layoutEffect: false,
  });

  return (
    <div className={styles.scheduleContainer}>
      <h2 className={styles.scheduleTitle}>2024-2025 Season Class Schedule</h2>
      <svg
        className={styles.progress}
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="30" pathLength="1" className={styles.bg} />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className={styles.indicator}
          style={{ pathLength: scrollXProgress }}
        />
      </svg>
      <div className={styles.scrollListContainer}>
        <ul ref={ref} className={styles.scrollList}>
          {Object.keys(classScheduleData).map((day, index) => (
            <li key={`day-${index}`} className={styles.day}>
              <h3 className={styles.dayTitle}>{day}</h3>
              <ul className={styles.classesList}>
                {classScheduleData[day].map((classItem, classIndex) => (
                  <li key={`class-${classIndex}`} className={styles.classItem}>
                    <span className={styles.classTime}>{classItem.time}</span>
                    <span className={styles.classRoom}>{classItem.room}</span>
                    <span className={styles.className}>{classItem.class}</span>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ClassSchedule;
