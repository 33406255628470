import { NavLink } from 'react-router-dom';
import Jazz1 from '../../images/Jazz1.webp';
import styles from './ClassSchedulePreview.module.css';

const ClassSchedulePreview = () => {
    const handleNavLinkClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <div className={styles.scheduleContainer}>
            <div className={styles.textContainer}>
                <NavLink to="/schedule" className={styles.scheduleButton} onClick={handleNavLinkClick}>
                    VIEW OUR SCHEDULE
                </NavLink>
            </div>
            <div className={styles.imageContainer}>
                <img src={Jazz1} alt="Class Schedule" className={styles.scheduleImage} />
            </div>
        </div>
    );
};

export default ClassSchedulePreview;
