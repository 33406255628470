import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Blue2 from '../../images/Blue2.webp';
import Juliet1 from '../../images/Juliet1.webp';
import styles from './ContactForm.module.css';
import useContactFormValidation from '../../hooks/useContactFormValidation';

const formFields = [
    { id: "name", label: "Name", type: "text" },
    { id: "phone", label: "Phone", type: "tel" },
    { id: "email", label: "Email", type: "email" },
    { id: "message", label: "Message", type: "textarea" },
];

const ContactForm = () => {
    const location = useLocation();
    const isEnrollPage = location.pathname === '/enroll';
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const { errors, isFormValid } = useContactFormValidation(formData);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const userId = process.env.REACT_APP_EMAILJS_USER_ID;

        emailjs.send(serviceId, templateId, formData, userId)
            .then((response) => {
                setResponseMessage("Message sent successfully!");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                    setFormData({ name: "", phone: "", email: "", message: "" });
                }, 5000);
            }, (error) => {
                setResponseMessage("Failed to send message. Please try again later.");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                }, 5000);
            });
    };

    return (
        <div className={`${styles.contactContainer} ${isEnrollPage ? styles.enrollBackground : styles.contactBackground}`}>
            <div className={styles.leftContainer}>
                <img src={isEnrollPage ? Juliet1 : Blue2} alt="Page Specific" className={styles.backgroundImage} />
            </div>
            <div className={styles.rightContainer}>
                <h2 className={styles.title}>{isEnrollPage ? "ENROLL NOW" : "CONTACT US"}</h2>
                <p className={styles.subtitle}>
                    {isEnrollPage ? "If you are interested in enrolling this season, please fill out the form and one of our team members will contact you." : "Please fill out the form and one of our team members will contact you."}
                </p>
                <form onSubmit={handleSubmit} className={styles.contactForm}>
                    {formFields.map(field => (
                        <div key={field.id} className={styles.formGroup}>
                            <div className={styles.labelWrapper}>
                                <label htmlFor={field.id} className={styles.label}>{field.label}</label>
                                {errors[field.id] && <span className={styles.error}>{errors[field.id]}</span>}
                            </div>
                            {field.type === "textarea" ? (
                                <textarea
                                    id={field.id}
                                    name={field.id}
                                    value={formData[field.id]}
                                    onChange={handleChange}
                                    className={styles.inputField}
                                    placeholder={`Enter your ${field.label.toLowerCase()}`}
                                    style={{ height: '5rem' }}
                                />
                            ) : (
                                <input
                                    type={field.type}
                                    id={field.id}
                                    name={field.id}
                                    value={formData[field.id]}
                                    onChange={handleChange}
                                    className={styles.inputField}
                                    placeholder={`Enter your ${field.label.toLowerCase()}`}
                                />
                            )}
                        </div>
                    ))}
                    {responseMessage && <p className={styles.responseMessage}>{responseMessage}</p>}
                    <div className={styles.submitButtonWrapper}>
                        <button type="submit" disabled={isSubmitting || !isFormValid} className={styles.submitButton}>Send</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
