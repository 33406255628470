import Header from "../Header/Header";
import Juliet2 from "../../images/Juliet2.webp";
import styles from './Title.module.css';

const Title = () => {
  const startYear = 1945; 
  const currentYear = new Date().getFullYear();
  const yearsInService = currentYear - startYear;

  return (
    <>
      <Header />
      <div className={styles.titleContainer}>
        <img src={Juliet2} alt="Ballerina" className={styles.backgroundImage} />
        <div className={styles.textContainer}>
          <p className={styles.years}>Cultivating the Art of Dance in New Mexico for {yearsInService} years.</p>
          <h1 className={styles.name}>FISHBACK STUDIO</h1>
        </div>
      </div>
    </>
  );
};

export default Title;
